/* eslint-disable no-param-reassign -- legacy TODO: remove */
/**
 * Prototype stuff
 */
Array.prototype.remove = function () {
  let what,
// eslint-disable-next-line prefer-rest-params, prefer-const
a = arguments,
L = a.length,
ax;
  while ( L && this.length ) {
      what = a[--L];
      // eslint-disable-next-line no-cond-assign
      while ( ( ax = this.indexOf( what ) ) !== -1 ) {
          this.splice( ax, 1 );
      }
  }

  return this;
};

Array.prototype.move = function ( oldIndex, newIndex ) {
  while ( oldIndex < 0 ) {
    oldIndex += this.length;
  }
  while ( newIndex < 0 ) {
      newIndex += this.length;
  }
  if ( newIndex >= this.length ) {
      let k = newIndex - this.length + 1;
      while ( k-- ) {
          this.push( undefined );
      }
  }
  this.splice( newIndex, 0, this.splice( oldIndex, 1 )[0] );

  return this;
};

/**
 * Main imports
*/

import { createApp, h, provide } from 'vue';
// import VueSession from 'vue-session';
import App from './App.vue';
import router from './router';
import store from './store';
import config from '../config';

/**
 * Plugins
 */
import Alerts from '@/plugins/alerts/index';
import Confirmation from '@/plugins/confirmation/index';
import Validate from '@/plugins/validate';
import Tables from '@/plugins/tables';

/**
 * Directives
 */
 import VueClickAway from 'vue3-click-away';

/**
* Vue component imports
*/
import MarkDown from '@/components/MarkDown';
import UIBreadCrumbs from '@/components/UI/BreadCrumbs.vue';
import UIReportInfo from '@/components/UI/ReportInfo.vue';
import UISeparator from '@/components/UI/Separator';
import UIBody from '@/components/UI/Body';
import UICardSet from '@/components/UI/CardSet';
import UICard from '@/components/UI/Card';
import UIModal from '@/components/UI/Modal';
import UIWidget from '@/components/UI/Widget';
import UIFragment from '@/components/UI/Fragment';
import UIDetails from '@/components/UI/Details';
import UIEmpty from '@/components/UI/Empty';
import UIButtonSet from '@/components/UI/ButtonSet';
import UIButton from '@/components/UI/Button';
import UILinkButton from '@/components/UI/LinkButton';
import UILinkButtonExt from '@/components/UI/LinkButtonExt';
import UISearch from '@/components/UI/Search';
import UITabList from '@/components/UI/TabList.vue';
import UITabPanel from '@/components/UI/TabPanel.vue';
import UIClearList from '@/components/UI/ClearList.vue';
import UIIssueLi from '@/components/UI/IssueLi.vue';
import UIAccordionLi from '@/components/UI/AccordionLi.vue';
import UIIcon from '@/components/UI/Icon.vue';
import UINotice from '@/components/UI/Notice';
import UIDataTable from '@/components/UI/DataTable';
import UITable from '@/components/UI/Table';
import UIWell from '@/components/UI/Well';
import Loader from '@/components/Loader';
import DateTime from '@/components/Helpers/DateTime';
import MapID from '@/components/Helpers/MapID';
import Screenshot from '@/components/Helpers/Screenshot';

import ApolloProvider from './setup/backend';
import Highlight from './setup/highlight';
import session from './setup/session';
import gettext from './setup/localisation';
import FontAwesomeIcon from './setup/fontawesome';

import Vue3GoogleLogin from 'vue3-google-login';
import { ApolloClients } from "@vue/apollo-composable";

createApp( {
  setup() {
    provide( ApolloClients, {
      default: ApolloProvider.clients.defaultClient,
    } );
  },
  render: () => h( App ),
} )
.use( ApolloProvider )
.use( {
  install( app ) {
    app.config.unwrapInjectedRef = true;
    app.config.globalProperties.$hugrConfig = config;
    app.config.globalProperties.$catchError = err => {
      // eslint-disable-next-line no-console
      console.log( 'CAUGHT ERROR', err, JSON.stringify( err ) );
    };
    app.config.globalProperties.$session = session;
    app.config.globalProperties.$uuid = {
      generate() {
        return ( [ 1e7 ] + -1e3 + -4e3 + -8e3 + -1e11 ).replace( /[018]/g, c =>
          // eslint-disable-next-line no-mixed-operators
          ( c ^ crypto.getRandomValues( new Uint8Array( 1 ) )[0] & 15 >> c / 4 ).toString( 16 ),
        );
      },
    };
  },
} )
.use( Vue3GoogleLogin, {
  clientId: '540616237993-tdae2nveoe2illukbkc7hfgtusg8u9a5.apps.googleusercontent.com',
} )
.use( router )
.use( store )
.use( gettext )
.use( Highlight )
.use( Alerts )
.use( Confirmation )
.use( Validate )
.use( Tables )
.use( VueClickAway )
.component( 'vue-markdown', MarkDown )
.component( 'font-awesome-icon', FontAwesomeIcon )
.component( 'BreadCrumbs', UIBreadCrumbs )
.component( 'ReportInfo', UIReportInfo )
.component( 'Separator', UISeparator )
.component( 'Body', UIBody )
.component( 'CardSet', UICardSet )
.component( 'Card', UICard )
.component( 'Modal', UIModal )
.component( 'Widget', UIWidget )
.component( 'Fragment', UIFragment )
.component( 'Details', UIDetails )
.component( 'Empty', UIEmpty )
.component( 'ButtonSet', UIButtonSet )
.component( 'Button', UIButton )
.component( 'LinkButton', UILinkButton )
.component( 'LinkButtonExt', UILinkButtonExt )
.component( 'Search', UISearch )
.component( 'TabList', UITabList )
.component( 'TabPanel', UITabPanel )
.component( 'ClearList', UIClearList )
.component( 'IssueLi', UIIssueLi )
.component( 'AccordionLi', UIAccordionLi )
.component( 'Icon', UIIcon )
.component( 'Notice', UINotice )
.component( 'DataTable', UIDataTable )
.component( 'Table', UITable )
.component( 'Well', UIWell )
.component( 'Loader', Loader )
.component( 'DateTime', DateTime )
.component( 'MapID', MapID )
.component( 'Screenshot', Screenshot )
.mount( '#app' );
