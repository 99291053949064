<template>
  <div v-if="type=='line'" :class="[`SeverityLine`, direction == 'vertical' ? `_flex` : ``, noMaxWidth?'_noMax':'']">
    <template v-for="item of parsedData" :key="item.key">
      <span v-if="typeof item.show == 'undefined' || item.show" :class="[`SeverityLine_Item`, direction == 'vertical' ? '_tall' : '']"
            :style="[direction == `vertical` ? `height:${item.percent}%; width: 16px; min-height: 4px;` : `width:${item.percent}%`]">
        
        <span :class="[`SeverityLine_Item_Inner`, `_${item.key.toLowerCase().replace(' ', '')}`,  direction == 'vertical' ? `_tall` : ``]" tabindex="0"
          @mouseenter="tooltips[item.key]?.showTooltip()"
          @mouseleave="tooltips[item.key]?.hideTooltip()"
          @focus="tooltips[item.key]?.showTooltip()"
          @blur="tooltips[item.key]?.hideTooltip()"></span>
        <Tooltip v-if="item.tooltip && item.tooltip != ''" :ref="( el ) => ( tooltips[ item.key ] = el )" :text="item.tooltip" :title="`${item.value} ${item.tooltipTitle}`" :closeness="direction == 'vertical' ? 'far' : 'close'"/>
      </span>
    </template>
  </div>
  <div v-if="type=='line'&&showKey" class="SeverityLineKey">
    <template v-for="item of parsedData" :key="item.key">
      <span class="SeverityLineKey_Item" v-if="typeof item.show == 'undefined' || item.show">
        <span v-if="keyDots" :class="['SeverityLineKey_Item_Dot', `_${item.key.toLowerCase().replace(' ', '')}`]"></span>
        <span class="SeverityLineKey_Item_Text">
          <span v-if="keyNumber=='after'" class="SeverityLineKey_Item_Text_Main">{{ item.key }} {{ item.value }}</span>
          <span v-if="keyNumber=='before'" class="SeverityLineKey_Item_Text_Main">{{ item.value }} {{ item.key }}</span>
          <span v-if="keyPerc" class="SeverityLineKey_Item_Text_Percent">({{ Math.round( item.percent ) }}%)</span>
        </span>
      </span>
    </template>
  </div>

  <div v-if="type=='pills'" class="SeverityPills">
    <template v-for="item of parsedData" :key="item.key">
      <Pill :text="item.value" :type="item.value!=0?item.key.toLowerCase():'inactive'" :circle="true" :tooltip="item.tooltip?item.tooltip:''" :tooltipTitle="item.tooltipTitle"/>
    </template>
  </div>
</template>

<script setup>
  import { computed, toRefs, ref, watch } from 'vue';
  import { sum, max } from 'lodash';

  import Pill from '@/components/UI/Pill';
  import Tooltip from '@/components/UI/Tooltip';

  const tooltips = ref( {} );

  const props = defineProps( {
    type: {
      type: String,
      default: 'pills',
    },
    direction: {
      type: String,
      default: 'horizontal',
    },
    showKey: {
      type: Boolean,
      default: false,
    },
    keyDots: {
      type: Boolean,
      default: true,
    },
    keyPerc: {
      type: Boolean,
      default: true,
    },
    keyNumber: {
      type: String,
      default: 'after',
    },
    noMaxWidth: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },

    advisory: {
      type: Number,
      default: 0,
    },
    low: {
      type: Number,
      default: 0,
    },
    medium: {
      type: Number,
      default: 0,
    },
    high: {
      type: Number,
      default: 0,
    },
    critical: {
      type: Number,
      default: 0,
    },
  } );

  const { data, advisory, low, medium, high, critical, direction } = toRefs( props );

  const myData = ref( [] );

  const total = computed( () => {
    if ( direction.value == 'vertical' ) return max( myData.value.filter( d => typeof d.show == 'undefined' || d.show ).map( d => d.value ) );

    return sum( myData.value.filter( d => typeof d.show == 'undefined' || d.show ).map( d => d.value ) );
  } );

  const parsedData = ref( {} );

  const parseData = () => {
    parsedData.value = myData.value.map( d => {
      return {
        ...d,
        percent: ( d.value / total.value ) * 100,
      };
    } );
  };

  const doLegacyData = () => {
    if( data.value.length !== 0 ) return;

    myData.value = [
      {
        key: 'Advisory',
        value: advisory.value,
        tooltipTitle: 'Advisory severity',
        tooltip: 'Issues that should be addressed to improve the user experience',
      },
      {
        key: 'Low',
        value: low.value,
        tooltipTitle: 'Low severity',
        tooltip: 'There may be a minor impact for some users',
      },
      {
        key: 'Medium',
        value: medium.value,
        tooltipTitle: 'Medium severity',
        tooltip: 'Some people may have difficulty using the product',
      },
      {
        key: 'High',
        value: high.value,
        tooltipTitle: 'High severity',
        tooltip: 'Issues that cause severe difficulties for some groups of people',
      },
      {
        key: 'Critical',
        value: critical.value,
        tooltipTitle: 'Critical severity',
        tooltip: 'Issues that make the product unusable',
      },
    ];
    parseData();
  };
  doLegacyData();
  watch( [ advisory, low, medium, high, critical ], doLegacyData );

  const mvData = () => {
    if( data.value.length ) {
      myData.value = data.value;
      parseData();
    }
  };
  mvData();
  watch( data, mvData );
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  .SeverityLine {
    width: 100%;
    max-width: 240px;
    height: 16px;
    background: $hugr-colours-input-surface;

    &._noMax {
      max-width: unset;
    }

    &._flex {
      display: flex;
      align-items: flex-end;
      column-gap: 8px;
      height: 100px !important;
      background: $hugr-colours-card-background;
    }

    &_Item {
      display: inline-block;
      min-height: 16px;
      position: relative;

      &._tall {
        min-height: 4px;
        height: 100%;
      }

      &_Inner {
        display: inline-block;
        width: 100%;
        height: 16px;
        position: relative;
        min-height: 4px;
        cursor: pointer;
        &:hover, &:focus, &._focus {
          transform: scale( 1.1 );
          z-index: 9;
        }

        &._tall {
          vertical-align: top;
          min-height: 4px;
          height: 100%;
        }
      }
    }
  }

  .SeverityLineKey {
    padding: 8px 0;
    &_Item {
      margin-right: 16px;
      &_Dot {
        display: inline-block;
        background: $hugr-colours-grey;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        margin-right: 8px;
        vertical-align: middle;
        position: relative;
        top: -1px;
      }
      &_Text {
        font-size: 0.8em;
        vertical-align: middle;
        &_Main {
          font-weight: bold;
        }
        &_Percent {
          margin-left: 4px;
          color: lighten( $hugr-colours-primary, 10% );
        }
      }
    }
  }

  .SeverityPills {
    max-width: 240px;
    display: flex;
    justify-content: flex-start;
    gap: 12px;
  }

  ._darkMode .SeverityLine {
    &._flex {
      background: lighten( $hugr-colours-primary, 10% );
    }
  }

  ._darkMode .SeverityLineKey {
    &_Item {
      &_Text {
        &_Percent {
          color: darken(white, 25% );
        }
      }
    }
  }

  ._advisory {
    background: $hugr-colours-pills-advisory;
  }
  ._low {
    background: $hugr-colours-pills-low;
  }
  ._medium {
    background: $hugr-colours-pills-medium;
  }
  ._high {
    background: $hugr-colours-pills-high;
  }
  ._critical {
    background: $hugr-colours-pills-critical;
  }

  ._pass {
    background: $hugr-colours-pills-pass;
  }
  ._fail {
    background: $hugr-colours-pills-fail;
  }
  ._notapplicable {
    background: $hugr-colours-pills-notapplicable;
  }
  ._untested {
    background: $hugr-colours-pills-inactive;
  }

</style>
