<template>
  <Widget title="Common issues" subtitle="The most frequently reoccurring issues" class="CriticalIssuesWidget" nominalWidth="4" height="3">
    <div v-for="( issue, i ) in sorted" :key="'issue-'+i">
      <div v-if="i < 6" class="Issue">
        <div class="Issue_Half">
          <h5 class="Issue_Half_Title">
            {{ issue.template.identifier }}
            {{issue.template.title}}
          </h5>
          <div class="Issue_Half_Count">
            <span class="Issue_Half_Count_Number">{{ issue.instanceCount }}</span>
            <span>&nbsp;{{ $gettext( "instance(s) on %{projects} %{projectString}(s)", { projectString: $hugrConfig.projectString, instances: issue.instanceCount, projects: issue.reports || '' } ) }}</span>
          </div>
        </div>
        <div class="Issue_Half">
          <div class="Bar">
            <div :class="['Bar_Inner', `__${[ 'advisory', 'low', 'medium', 'high', 'critical' ][ issue.template.severity ]}`]" :style="`width: ${issue.instanceCount / sorted[0].instanceCount * 100}%`"></div>
          </div>
          <div class="Issue_Half_Criteria">
            {{ [ 'Advisory', 'Low', 'Medium', 'High', 'Critical' ][ issue.template.severity ] }}  -
            <span v-for=" (criterion, k) in issue.template.criteria" :key="`criteria-`+k">
              WCAG {{ criterion.criterion }} ({{ criterion.level }})
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- <Empty v-if="data.length == 0" :text="$gettext( 'No issues on any %{projectString}s', { projectString: $hugrConfig.projectString } )" /> -->
  </Widget>
</template>

<script setup>
  import { ref, computed, inject } from 'vue';
  import { useQuery } from "@vue/apollo-composable";
  import gql from "graphql-tag";

  const alerts = inject( 'alerts' );

  const statsgql = `
    statistics {
      id
      commonIssues {
        template {
          _id
          identifier
          title
          severity

          criteria {
            _id
            criterion
            level
          }
        }
        instanceCount
        reports


        instances {
          _id
          identifier
          reportVersion {
            _id
            version
            report {
              _id
              identifier
            }
          }
        }
      }
    }`;

  const { onResult, refetch, onError } = useQuery( gql`
    query {
      me: WhoAmI {
        _id
        ${statsgql}
      }
    }
  ` );

  const statistics = ref( false );

  onResult( ( { data } ) => {
    if( data ) statistics.value = data.me.statistics;
  } );

  onError( error => {
    alerts.generic( error );
  } );

  const sorted = computed( () => {
    if( typeof statistics.value.commonIssues == 'undefined' ) return [];
    
    return [ ...statistics.value.commonIssues ].sort( ( a,b ) => b.instanceCount - a.instanceCount );
  } );
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .Bar {
    width: 100%;
    height: 24px;
    margin-bottom: 8px;

    &_Inner {
      height: 24px;
      background: $hugr-colours-grey;

      &.__advisory { background-color: $hugr-colours-pills-advisory; }
      &.__low { background-color: $hugr-colours-pills-low; }
      &.__medium { background-color: $hugr-colours-pills-medium; }
      &.__high { background-color: $hugr-colours-pills-high; }
      &.__critical { background-color: $hugr-colours-pills-critical; }

    }
  }

  .Issue {
    display: flex;
    padding: 8px 0;
    border-bottom: 1px solid $hugr-colours-grey;

    &_Half {
      width: 48%;
      margin-right: 8px;

      &_Title {
        margin-bottom: 8px;
        margin-top: 0;
        font-weight: bold;
        font-size: 11pt;
      }

      &_Count {
        font-size: 10pt;
        margin-top: -4px;
        &_Number {
          font-weight: bold;
        }
      }

      &_Criteria {
        font-size: 10pt;
        margin-top: -6px;
      }
    }
  }
  // .widget {
  //   .container {
  //     padding: 20px;
  //     .inner {
  //       height: 100%;
  //       position: relative;
  //     }
  //   }
  // }
</style>
