<template>
  <div class="FilterOpt">
    <button :class="['FilterOpt_Button', showOpts?'__open':'']" @click.prevent="toggleOpts" :aria-expanded="showOpts?'true':'false'" :aria-controls="`${uid}-opts`">
      {{ title }}
      <span v-if="ghostValue.length"> ({{ghostValue.length}})</span>
      <Icon class="FilterOpt_Button_Icon" type="solid" :icon="showOpts?'chevron-up':'chevron-down'" />
    </button>
    <div class="FilterOpt_Opts" v-if="showOpts" :id="`${uid}-opts`">
      <ul class="FilterOpt_Opts_List">
        <!-- <li class="FilterOpt_Opts_List_Item" v-if="showAllOpt"><input v-model="value" value="showAll" type="checkbox" aria-labelledby="showAll-label"/> <span id="showAll-label">Show All</span></li> -->
        <li class="FilterOpt_Opts_List_Item" v-for="(value, key) in options" :key="`${key}-li`">
          <input class="FilterOpt_Opts_List_Item_Input" v-model="ghostValue" :value="key" type="checkbox" :id="`${key}-input`"/>
          <label class="FilterOpt_Opts_List_Item_Text" :for="`${key}-input`">
            <span>{{ value }}</span>
            <span class="FilterOpt_Opts_List_Item_Text_Extra" v-if="extra&&extra.hasOwnProperty(key)">{{ extra[key] }}</span>
          </label>
        </li>
      </ul>
      <div class="FilterOpt_Opts_Actions">
        <Button class="__clear" type="transparent" size="micro" @click="clear">Clear</Button>
        <Button class="__save" type="secondary" size="micro" @click="save">Apply</Button>
      </div>
    </div>
  </div>
</template>

<script>

let t;

export default {
  name: 'UIFilterOpt',
  inject: [ 'signal' ],
  data() {
    return {
      uid: this.$uuid.generate(),

      showOpts: false,
      currValue: [ ...this.default ],
      ghostValue: [ ...this.default ],
    };
  },
  watch: {
    signal() {
      this.closeCheck();
    },
  },
  methods: {
    closeCheck() {
      if( this.signal != this.uid && this.showOpts ) {
        this.doUnshowOpts();
      } else {
        t = setTimeout( () => {
          this.closeCheck();
        }, 200 );
      }
    },
    clickAway() {
      this.doUnshowOpts();
    },
    toggleOpts() {
      if( !this.showOpts ) {
        this.doShowOpts();
      } else {
        this.doUnshowOpts();
      }
    },
    doShowOpts() {
      this.ghostValue = [ ...this.currValue ];
      this.$emit( 'open', this.uid );
      setTimeout( () => {
        this.showOpts = true;
        this.closeCheck();
      }, 10 );
    },
    doUnshowOpts() {
      this.showOpts = false;
      this.ghostValue = [ ...this.currValue ];
      clearTimeout( t );
    },
    save() {
      this.$emit( 'update', this.ghostValue );
      this.currValue = [ ...this.ghostValue ];
      this.doUnshowOpts();
    },
    saveWithoutClosing() {
      this.$emit( 'update', this.ghostValue );
      this.currValue = [ ...this.ghostValue ];
    },
    clear() {
      this.currValue = [];
      this.ghostValue = [];
      this.saveWithoutClosing();
      //should this save and close or not save and stay open?
      // this.$emit('change', this.value);
      // this.doUnshowOpts();
    },
    clearAndSave() {
      this.clear();
      this.save();
    },
    ghostClear() {
      this.currValue = [];
      this.ghostValue = [ ...this.currValue ];
    },
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    extra: {
      required: false,
      default: () => {
        return {};
      },
    },
    default: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  @import '@/assets/styles/variables/_header.scss';

  .FilterOpt {
    display: inline-block;
    vertical-align: top;

    &_Button {
      padding: 8px;
      background: white;
      border: 1px solid $hugr-colours-grey;
      border-radius: 4px;

      // border-right: 1px solid $hugr-colours-grey;

      transition: background-color .5s ease-in-out 0s, color .5s ease-in-out 0s;

      &_Icon {
        margin-left: 4px;
      }

      &.__open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:hover, &:focus, &.__open {
        background: lighten($hugr-colours-grey, 10%);
        cursor: pointer;
      }
    }
    &_Opts {
      position: absolute;
      top: 33px;
      background: white;
      border: 1px solid $hugr-colours-grey;
      width: 250px;
      z-index: 9999;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
      box-shadow: $hugr-header-regular-shadow;

      &_List {
        list-style: none;
        margin: 0;
        padding: 10px 0;

        &_Item {
          padding: 10px;

          &_Input {
            vertical-align: middle;
          }
          &_Text {
            display: inline-block;
            vertical-align: middle;
            padding-left: 10px;
            max-width: 200px;

            > * {
              display: block;
            }
            &_Extra {
              font-size: 9pt;
              color: $hugr-colours-primary;
            }
          }
        }
      }
      &_Actions {
        position: relative;
        border-top: 1px solid $hugr-colours-grey;
        padding: 5px;

        .__save {
          position: absolute;
          right: 10px;
        }
      }
    }
  }

  ._darkMode .FilterOpt {
    &_Button {
      background: $hugr-colours-primary;
      color: #FFF;
      &:hover, &:focus, &.__open {
        background: darken($hugr-colours-primary, 10%);
      }
    }

    &_Opts {
      background: $hugr-colours-primary;
      &_List {
        &_Item {
          // &_Input {
          //   background: $hugr-colours-primary;
          // }
          &_Text {
            &_Extra {
              color: $hugr-colours-grey;
            }
          }
        }
      }
    }
  }
</style>
