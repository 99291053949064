<template>
  <Widget title="Total issues" class="TotalIssuesWidget" nominalWidth="8">
    <Pie class="TotalIssuesWidget_Pie" :data="pieData" :cardMode="true" centerKey="" keyStyle="pills" size="micro"/>
  </Widget>
</template>

<script setup>
  import { ref, computed, inject } from 'vue';
  import { useQuery } from "@vue/apollo-composable";
  import gql from "graphql-tag";
  import { sum } from 'lodash';

  import Pie from '@/components/UI/Pie';

  const alerts = inject( 'alerts' );

  const statsgql = `
    statistics {
      id
      reports {
        id
        issues {
          advisory
          low
          medium
          high
          critical
        }
      }
    }`;

  const { onResult, refetch, onError } = useQuery( gql`
    query {
      me: WhoAmI {
        _id
        ${statsgql}
      }
    }
  ` );

  const statistics = ref( false );

  onResult( ( { data } ) => {
    if( data ) statistics.value = data.me.statistics;
  } );

  onError( error => {
    alerts.generic( error );
  } );

  const allIssues = computed( () => {
    if( !statistics.value ) return {};

    return {
      advisory: sum( statistics.value.reports.map( r => r.issues.advisory ) ),
      low: sum( statistics.value.reports.map( r => r.issues.low ) ),
      medium: sum( statistics.value.reports.map( r => r.issues.medium ) ),
      high: sum( statistics.value.reports.map( r => r.issues.high ) ),
      critical: sum( statistics.value.reports.map( r => r.issues.critical ) ),
    };
  } );

  const pieData = computed( () => {
    if( !statistics.value ) return [];

    return [
    {
        key: 'Advisory',
        value: allIssues.value.advisory,
        colour: '#879441',
      },
      {
        key: 'Low',
        value: allIssues.value.low,
        colour: '#009620',
      },
      {
        key: 'Medium',
        value: allIssues.value.medium,
        colour: '#FBB55E',
      },
      {
        key: 'High',
        value: allIssues.value.high,
        colour: '#FAC0BF',
      },
      {
        key: 'Critical',
        value: allIssues.value.critical,
        colour: '#B22C2A',
      },
    ];
  } );
</script>

<style lang="scss" scoped>

</style>
