<template>
  <Widget title="Accessibility Statements" subtitle="Accessibility statements needing attention" class="AccessibilityStatementsWidget" nominalWidth="4" height="3">
    <div class="AccessibilityStatementsWidget_Counts" v-if="statistics">
      <div class="AccessibilityStatementsWidget_Counts_Count">
        <span class="AccessibilityStatementsWidget_Counts_Count_Number">{{ statistics.reports.filter( r => !r.report.currentVersion.accessibilityStatementOverrides.statementURL || r.report.currentVersion.accessibilityStatementOverrides.statementURL == '' ).length }}&nbsp;</span>
        <span class="AccessibilityStatementsWidget_Counts_Count_State __missing">
          <Icon type="solid" icon="question" />&nbsp;
          <span>Missing</span>
        </span>
      </div>
      <div class="AccessibilityStatementsWidget_Counts_Count">
        <span class="AccessibilityStatementsWidget_Counts_Count_Number">{{ statistics.reports.filter( r => r.report.currentVersion.accessibilityStatementOverrides.statementURL && calculateExpiryDateDifference( r.report.currentVersion.accessibilityStatementOverrides.lastReviewDate ) <= 0 ).length }}&nbsp;</span>
        <span class="AccessibilityStatementsWidget_Counts_Count_State __outofdate">
          <Icon type="solid" icon="calendar-xmark" />&nbsp;
          <span>Out of date</span>
        </span>
      </div>
      <div class="AccessibilityStatementsWidget_Counts_Count">
        <span class="AccessibilityStatementsWidget_Counts_Count_Number">{{ statistics.reports.filter( r => r.report.currentVersion.accessibilityStatementOverrides.statementURL && calculateExpiryDateDifference( r.report.currentVersion.accessibilityStatementOverrides.lastReviewDate ) > 0 ).length }}&nbsp;</span>
        <span class="AccessibilityStatementsWidget_Counts_Count_State __valid">
          <Icon type="solid" icon="check" />&nbsp;
          <span>Valid</span>
        </span>
      </div>
    </div>
    <ul class="AccessibilityStatementsWidget_List">
      <li class="AccessibilityStatementsWidget_List_Item" v-for="report in statistics.reports" :key="report.id">
        <span class="AccessibilityStatementsWidget_List_Item_Report">
          <Icon preset="project" />&nbsp;
          <router-link :to="`/${$hugrConfig.reportRouterReplacement}s/view/${report.report.identifier}`">{{ report.report.title }}</router-link>
        </span>

        <span class="AccessibilityStatementsWidget_List_Item_State __missing" v-if="!report.report.currentVersion.accessibilityStatementOverrides.statementURL || report.report.currentVersion.accessibilityStatementOverrides.statementURL == ''">
          <Icon type="solid" icon="question" />&nbsp;
          <span>Missing</span>
        </span>
        <span class="AccessibilityStatementsWidget_List_Item_State __outofdate" v-else-if="calculateExpiryDateDifference( report.report.currentVersion.accessibilityStatementOverrides.lastReviewDate ) <= 0">
          <Icon type="solid" icon="calendar-xmark" />&nbsp;
          <span>Out of date</span>
        </span>
        <span class="AccessibilityStatementsWidget_List_Item_State __valid" v-else>
          <Icon type="solid" icon="check" />&nbsp;
          <span>Valid</span>
        </span>
      </li>
    </ul>
  </Widget>
</template>

<script setup>
  import { ref, computed, inject } from 'vue';
  import { useQuery } from "@vue/apollo-composable";
  import gql from "graphql-tag";
  import moment from 'moment';

  const alerts = inject( 'alerts' );

  const statsgql = `
    statistics {
      id
      reports {
        id
        report {
          _id
          identifier
          title
          currentVersion {
            accessibilityStatementOverrides {
              statementURL,
              lastReviewDate,
              legalStatus,
              complianceStatus,
              lastAssuredDate,
              assuranceNote,
            }
          }
        }
      }
    }`;

  const { onResult, refetch, onError } = useQuery( gql`
    query {
      me: WhoAmI {
        _id
        ${statsgql}
      }
    }
  ` );

  const statistics = ref( false );

  onResult( ( { data } ) => {
    if( data ) statistics.value = data.me.statistics;
  } );

  onError( error => {
    alerts.generic( error );
  } );

  const calculateExpiryDateDifference = date => {
    if ( moment( date, true ).isValid() ) {
      return moment( date, true ).add( 12, 'months' ).diff( moment() );
    }

    return false;
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  .AccessibilityStatementsWidget {
    &_Counts {
      &_Count {
        display: inline-block;
        border-right: 1px solid $hugr-colours-grey;
        padding: 16px 16px 16px 0px;
        margin-right: 16px;
        &_Number {
          display: block;
          font-size: 1.2em;
          font-weight: bold;
        }
        &_State {
          font-weight: bold;
        }
      }
    }
    &_List {
      list-style: none;
      padding: 0;
      &_Item {
        display: flex;
        justify-content: space-between;
        margin: 8px 0;
        &_Report {
          display: flex;
          width: 60%;
          a {
            text-decoration: none;
          }
        }
        &_State {
          font-weight: bold;
        }
      }
    }
  }

  .__missing {
    color: $hugr-colours-pills-medium;
  }

  .__outofdate {
    color: $hugr-colours-pills-critical;
  }

  .__valid {
    color: $hugr-colours-pills-lowest;
  }
</style>
