<template>
  <div :class="['Widget', size == 'long' ? '_long':'', `__width-${nominalWidth}`, `__height-${height}`]">
    <div class="Widget_Head" v-if="!hideTitle">
      <h4>{{ title }}</h4>
      <h5 v-if="subtitle!=''">{{ subtitle }}</h5>
    </div>
    <div :class="['Widget_Container', hideTitle?'__noHead':'', subtitle?'__hasSubtitle':'']">
      <div class="Widget_Container_Inner">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UIWidgetBase',
  props: {
    title: {
      type: String,
      default: "Untitled widget",
    },
    subtitle: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: "normal",
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    nominalWidth: {
      type: Number,
      default: 4,
    },
    height: {
      type: Number,
      default: 1,
    },
  },
  components: {

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  @import '@/assets/styles/variables/_fonts.scss';
  @import '@/assets/styles/variables/_mixins.scss';

  .Widget {
    min-height: 50px;
    // min-width: 400px;
    width: 30%;
    background: $hugr-colours-card-background;
    border: 1px solid $hugr-colours-grey;
    flex: 1 1 auto;

    &.__width-1 { width: 8% }
    &.__width-2 { width: 16% }
    &.__width-4 { width: 32% }
    &.__width-6 { width: 49% }
    &.__width-8 { width: 64% }

    $heightUnit: 170px;

    &.__height-1 { height: calc( 1 * $heightUnit ) }
    &.__height-2 { height: calc( 2 * $heightUnit ) }
    &.__height-3 { height: calc( 3 * $heightUnit ) }
    &.__height-4 { height: calc( 4 * $heightUnit ) }

    &._long {
      width: 100%;
    }
    &_Head {
      padding: 8px;
      padding-bottom: 0;
      h4 {
        margin: 0;
        font-family: $hugr-fonts-body;
        font-weight: bold;
      }
      h5 {
        margin: 0;
        margin-top: 4px;
        font-family: $hugr-fonts-body;
        font-weight: bold;
        color: lighten( $hugr-colours-primary, 10% );
      }
    }
    &_Container {
      padding: 8px;
      max-height: calc( 99% - 32px );
      box-sizing: border-box;
      @include vertical-scroll-overscroll;

      &.__noHead {
        max-height: calc( 99% );
      }

      &.__hasSubtitle {
        max-height: calc( 99% - 52px );
      }

      &_Inner {
        height: 100%;
        position: relative;
      }
    }
  }

  ._darkMode .Widget{
    background: lighten( $hugr-colours-primary, 10% );
    color: #FFF;
  }

  @media screen and (max-width: 400px) {
    .Widget {
      min-width: 100%;
    }
  }
</style>
