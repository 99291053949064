<template>
  <Widget title="Issue distribution by severity" class="IssueDistributionWidget" nominalWidth="6" height="3">
    <template v-for="report in statistics.reports">
      <div class="IssueDistributionWidget_Item" v-if="countBy( report.issues )['0'] < 5 || typeof countBy( report.issues )['0'] == 'undefined'" :key="report.id">
        <span class="IssueDistributionWidget_Item_Details">
          <Icon type="solid" icon="box" />&nbsp;
          <router-link :to="`/${$hugrConfig.reportRouterReplacement}s/view/${report.report.identifier}`">{{ report.report.title }}</router-link>
        </span>
        <span class="IssueDistributionWidget_Item_Severity">
          <SeverityLine
            type="line"
            :noMaxWidth="true"
            :showKey="true"
            :keyDots="false"
            :keyPerc="false"
            keyNumber="before"

            :advisory="report.issues.advisory"
            :low="report.issues.low"
            :medium="report.issues.medium"
            :high="report.issues.high"
            :critical="report.issues.critical"
            />
        </span>
        <Pill :text="pass?'Pass':'Fail'" class="IssueDistributionWidget_Item_Pill"/>
      </div>
    </template>
  </Widget>
</template>

<script setup>
  import { ref, computed, inject } from 'vue';
  import { useQuery } from "@vue/apollo-composable";
  import gql from "graphql-tag";
  import { countBy } from 'lodash';

  import Pill from '@/components/UI/Pill';
  import SeverityLine from '@/components/UI/SeverityLine';

  const alerts = inject( 'alerts' );

  const statsgql = `
    statistics {
      id
      reports {
        id
        pass
        report {
          _id
          identifier
          title
        }
        issues {
          advisory
          low
          medium
          high
          critical
        }
      }
    }`;

  const { onResult, refetch, onError } = useQuery( gql`
    query {
      me: WhoAmI {
        _id
        ${statsgql}
      }
    }
  ` );

  const statistics = ref( false );

  onResult( ( { data } ) => {
    if( data ) statistics.value = data.me.statistics;
  } );

  onError( error => {
    alerts.generic( error );
  } );
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  .IssueDistributionWidget {
    &_Item {
      background: lighten( $hugr-colours-grey, 10% );
      border: 1px solid $hugr-colours-grey;
      display: flex;
      gap: 16px;
      margin-bottom: 8px;
      padding: 8px;
      &_Details {
        width: 30%;
        font-size: 0.9em;
        a {
          text-decoration: none;
          font-weight: bold;
        }
      }
      &_Severity {
        width: 60%;
      }
      &_Pill {
        width: 10%;
        text-align: right;
      }
    }
  }
</style>
