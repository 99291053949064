<template>
  <div class="Pill">
    <div  :class="['Pill_Main', `__size-${size}`, `__${mType}`, tooltip!=''?'_hasTooltip':'', circle?'_circle':'', checkable?'__bordered':'', checkable&&!checked?'__unchecked':'']"
          @mouseenter="tooltipEl?.showTooltip()"
          @mouseleave="tooltipEl?.hideTooltip()"
          @focus="tooltipEl?.showTooltip()"
          @blur="tooltipEl?.hideTooltip()"
          :tabindex="tooltip!=''?'0':'-1'"
      >
      <div class="Pill_Main_Inner" :aria-hidden="hintMode">
        <div class="Pill_Main_Inner_Main">
          <template v-if="icon.length">
            <img v-if="icon[0]=='ml'" :src="require('@/assets/images/sparkle-white.svg')" alt="" />
            <Icon v-else :type="icon[0]" :icon="icon[1]" />
          </template>
          <Icon v-if="checkable" type="regular" :icon="checked ? 'circle-check' : 'circle'" />
          {{ text }}
          <Button v-if="dismissable" type="icon" size="micro" :icon="['solid', 'times']" @click.prevent="emit( 'dismiss' )" :title="dismissAlt">{{ dismissAlt }}</Button>
        </div>
        <div v-if="subtext!=''" :class="['Pill_Main_Inner_Subtext', subtext!=''?'_huge':'']">
          {{ subtext }}
        </div>
      </div>
    </div>
    <Tooltip v-if="tooltip!=''" ref="tooltipEl" :text="tooltip" :title="tooltipTitle" :wider="widerTooltip"/>
  </div>
</template>

<script setup>
  import { toRefs, ref, watch } from 'vue';
  import Tooltip from './Tooltip.vue';

  const emit = defineEmits( [ 'dismiss' ] );

  const props = defineProps( {
    type: {
      type: String,
      default: 'unknown',
    },
    circle: {
      type: Boolean,
      default: false,
    },
    text: String,
    size: {
      type: String,
      default: 'normal',
    },
    subtext: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    hintMode: {
      type: Boolean,
      default: false,
    },
    widerTooltip: {
      type: Boolean,
      default: false,
    },
    tooltipTitle: {
      type: String,
      default: '',
    },

    checkable: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },

    dismissable: {
      type: Boolean,
      default: false,
    },
    dismissAlt: {
      type: String,
      default: 'dismiss',
    },

    icon: {
      type: Array,
      default: () => {
        return [];
      },
    },
  } );

  const { type, text, size, tooltip } = toRefs( props );

  const mType = ref( type.value );
  const tooltipEl = ref( null );

  const setDefaultType = () => {
    if( type.value == 'unknown' ) {
      if( text.value == 'Pass' ) mType.value = 'pass';
      if( text.value == 'Fail' ) mType.value = 'fail';
      if( text.value == 'Advisory' ) mType.value = 'advisory';
      if( text.value == 'Not Applicable' ) mType.value = 'notapplicable';

      if( text.value == 'Low' ) mType.value = 'low';
      if( text.value == 'Medium' ) mType.value = 'medium';
      if( text.value == 'High' ) mType.value = 'high';
      if( text.value == 'Critical' || text.value == 'Highest' ) mType.value = 'critical';
      if( text.value == 'Lowest' ) mType.value = 'lowest';

      if( text.value == 'Beta' ) mType.value = 'beta';
    }
  };

  setDefaultType();

  watch( text, () => {
    setDefaultType();
  } );

</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .Pill {
    position: relative;
    display: inline-block;

    &_Main {
      background: $hugr-colours-grey;
      color: $hugr-colours-primary;
      border-radius: 5px;
      padding: 6px;
      text-align: center;
      font-family: "Quicksand", sans-serif;
      font-size: 12pt;
      font-weight: bold;
      display: inline-block;
      white-space: nowrap;
      // border: 1px solid;

      &_Inner {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      flex-direction: column;

        &_Subtext {
          font-size: 8px;
          font-weight: normal;

          &._huge {
            font-size: 0.3em;
          }
        }
      }

      &.__beta {
        background: #9d4afc;
        color: white;
      }

      &.__advisory {
        background: $hugr-colours-pills-advisory;
        color: $hugr-colours-pills-advisory-text;
      }
      &.__lowest {
        background: $hugr-colours-pills-lowest;
        color: $hugr-colours-pills-lowest-text;
      }

      &.__positive {
        background: $hugr-colours-pills-positive;
        color: $hugr-colours-pills-positive-text;
      }
      &.__low {
        background: $hugr-colours-pills-low;
        color: $hugr-colours-pills-low-text;
      }
      &.__pass {
        background: $hugr-colours-pills-pass;
        color: $hugr-colours-pills-pass-text;
      }

      &.__negative {
        background: $hugr-colours-pills-negative;
        color: $hugr-colours-pills-negative-text;
      }
      &.__high {
        background: $hugr-colours-pills-high;
        color: $hugr-colours-pills-high-text;
      }
      &.__fail {
        background: $hugr-colours-pills-fail;
        color: $hugr-colours-pills-fail-text;
      }

      &.__warning {
        background: $hugr-colours-pills-warning;
        color: $hugr-colours-pills-warning-text;
      }
      &.__medium {
        background: $hugr-colours-pills-medium;
        color: $hugr-colours-pills-medium-text;
      }

      &.__critical {
        background: $hugr-colours-pills-critical;
        color: $hugr-colours-pills-critical-text;
      }

      &.__notapplicable {
        background: $hugr-colours-pills-notapplicable;
        color: $hugr-colours-pills-notapplicable-text;
      }

      &.__inactive {
        background: $hugr-colours-pills-inactive;
        color: $hugr-colours-pills-inactive-text;
      }

      &.__unchecked {
        background: white;
        color: $hugr-colours-pills-inactive-text;
      }

      &.__bordered {
        border: 1px solid;
      }

      &.__size-small {
        font-size: 0.8em;
        padding: 4px;
      }
      &.__size-micro {
        font-size: 0.6em;
        padding: 2px;
      }

      &.__size-huge {
        font-size: 2.5em;
        width: 120px !important;
        height: 120px !important;
      }

      &._circle {
        border-radius: 100px;
        width: 18px;
        height: 18px;
      }

      &.__note {
        background: $hugr-colours-hint-green;
        color: #000;
      }

      &._hasTooltip {
        cursor: pointer;
      }
    }
  }
</style>
