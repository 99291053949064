<template>
  <div :class="darkMode && user.loggedIn ?'_darkMode':''">
    <a v-translate class="a11y-skip button secondary" href="#main">Skip to content</a>
    <template v-if="loaded">
      <template v-if="user.loggedIn">
        <template v-if="(this.$route.meta.acl.indexOf(this.aclevel)>=0 || (routeHasPermissionRequirement && hasPermissions(this.$route.meta.requiredPermissions))) && !requiresMfaSetup && !requiresPasswordReset">

          <Header v-if="!undecorated" v-bind:navigation="navigation" v-bind:user="user" @lights="lights()"></Header>
          <div :class="['ContentContainer', undecorated?'_undecorated':'', hasStatementLink?'_withFooter':'']">
            <main id="main" class="ContentContainer_Body" tabindex="-1">
              <router-view/>
            </main>
          </div>
        </template>
        <template v-else-if="this.$route.meta.acl.indexOf(this.aclevel)>=0 && requiresMfaSetup && !requiresPasswordReset">
          <Header :navigation="navigation" :user="user" @lights="lights()"></Header>
          <div class="ContentContainer">
            <main id="main" class="ContentContainer_Body" tabindex="-1">
              <Body title="You need to enable two factor authentication">
                <h3>Team(s) requiring two factor authentication:</h3>
                <ul>
                  <li v-for="mfaTeam in mfaTeams" :key="mfaTeam._id">{{ mfaTeam.name }}</li>
                </ul>
                <MFAForm @success="requiresMfaSetup = false" />
              </Body>
            </main>
          </div>
        </template>
        <template v-else-if="this.$route.meta.acl.indexOf(this.aclevel)>=0 && requiresPasswordReset">
          <Header :navigation="navigation" :user="user" @lights="lights()"></Header>
          <div class="ContentContainer">
            <main id="main" class="ContentContainer_Body" tabindex="-1">
              <Body :title="$gettext('You need to reset your password.')">
                <PasswordResetForm @success="updatePasswordResetRequired" />
              </Body>
            </main>
          </div>
        </template>

        <template v-else >
          <p v-translate>ACL error</p>
        </template>

      </template>
      <template v-else-if="this.$route.meta.acl.indexOf(10)>=0">
        <Header v-if="!undecorated" :visitor="true"></Header>
        <div :class="['ContentContainer', undecorated?'_undecorated':'']">
          <main id="main" class="ContentContainer_Body" tabindex="-1">
            <router-view/>
          </main>
        </div>
      </template>
      <template v-else>
        <Login v-if="!signingUp && !forgotPassword" @success="loggedIn" @changeForm="signingUp = true;" @forgotPassword="forgotPassword = true;" v-bind:mfaSuppliedMagicCode="mfaMagicCode" v-bind:mfaSuppliedID="mfaID" v-bind:externalMFACheck="externalMFACheck"/>
        <Register v-else-if="signingUp" @success="loggedIn" v-bind:magicCode="magicCode" v-bind:email="email" v-bind:nameInput="nameInput" @change-form="signingUp = false;" />
        <ForgotPassword v-else-if="forgotPassword" @success="loggedIn" v-bind:pwdResetCode="pwdResetCode" v-bind:email="email" @changeForm="forgotPassword = false;" @mfaCheck="mfaCheck"/>
        <hugr-alerts :noHeader="true"/>
      </template>
    </template>
    <div v-else>
      <Header v-bind:navigation="{}" :placeholder="true"></Header>
      <Loader />
    </div>
    <footer v-if="hasStatementLink" class="Footer">
      <div class="Footer_Left">
        <Pill v-if="config.footerPill!=''" :text="config.footerPill" size="small" type="note"/>
      </div>
      <div class="Footer_Right">
        <a :href="`mailto:${config.footerContact}`">Contact us</a>
        <a v-if="config.footerForm!=''" :href="config.footerForm">{{ config.footerFormText }}</a>
        <router-link to="/accessibility-statement">Accessibility Statement</router-link>
      </div>
    </footer>
    <hugr-confirm />
  </div>
</template>
<script>
import gql from 'graphql-tag';
import { mapState, mapActions, mapGetters } from 'vuex';
import Header from './components/Header';

import Pill from './components/UI/Pill';

import Login from './views/Login';
import Register from './views/Register';
import ForgotPassword from './views/ForgotPassword';

import MFAForm from './forms/MFAForm';
import PasswordResetForm from './forms/PasswordReset';
import UIBody from '@/components/UI/Body';

import config from '../config';

export default {
  name: 'App',
  setup() {

  },
  mounted() {
    if( !document.getElementsByTagName( 'body' )[0].classList.contains( '_darkify' ) && localStorage.getItem( 'darkmode' ) == 'true' ) {
      document.getElementsByTagName( 'body' )[0].classList.add( '_darkify' );
    }
  },
  data() {
    const urlParams = new URLSearchParams( window.location.search );

    return {
      config,
      forgotPassword: urlParams.get( 'resettingPassword' ) || false,
      pwdResetCode: urlParams.get( 'pwdResetCode' ) || '',
      signingUp: urlParams.get( 'signingUp' ) || false,
      magicCode: urlParams.get( 'magicCode' ) || '',
      mfaMagicCode: urlParams.get( 'mfaMagicCode' ) || '',
      mfaID: urlParams.get( 'mfaID' ) || '',
      externalMFACheck: false,
      nameInput: urlParams.get( 'name' ) || '',
      email: urlParams.get( 'email' ) || '',
      requiresMfaSetup: false,
      requiresPasswordReset: false,
      mfaTeams: [],
      aclevel: 9,
      loaded: false,
      navigation: {
        Home: '/',
      },

      darkMode: localStorage.getItem( 'darkmode' ) == 'true',
      hasPortfolios: false,
      hasStatementLink: false,

      undecorated: this.$route.name == 'ReportsRenderUndecorated',
    };
  },
  created() {
    this.setTitle();
    this.$apollo.query( {
      query: gql`
        query Settings {
          settings: Settings {
            id
            portfolios,
            statementLink
          }
        }
      `,
    } ).then( res => {
      this.hasPortfolios = res.data.settings.portfolios;
      this.hasStatementLink = res.data.settings.statementLink;
      this.checkUser();
    } ).catch( () => {
      this.$alerts.coded( 'E001', 'F001' ); //see notifications spreadsheet
    } );
  },
  watch: {
    $route() {
      this.setTitle();
    },
  },
  methods: {
    ...mapActions( [ 'setUser', 'setReport' ] ),
    updatePasswordResetRequired() {
      this.requiresPasswordReset = false;
      this.$forceUpdate();
    },
    setTitle() {
      let newTitle = 'HUGR';

      if( this.$route.meta.title ) {
        newTitle = `HUGR | ${this.$route.meta.title}`;
      }

      if ( newTitle.indexOf( 'Report' ) >= 0 ) {
        newTitle = newTitle.replaceAll( 'Report', config.projectStringCap );
      }

      document.title = newTitle;
    },
    loggedIn() {
      this.checkUser();
    },
    lights() {
      this.darkMode = localStorage.getItem( 'darkmode' ) == 'true';
      if( this.darkMode ) {
        document.getElementsByTagName( 'body' )[0].classList.add( '_darkify' );
      } else {
        document.getElementsByTagName( 'body' )[0].classList.remove( '_darkify' );
      }
    },
    userHasBeenWarned( user ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation setUserSetting($id: ObjectID!, $key: String!, $value: String!) {
            res: setUserSetting(id: $id, key: $key, value: $value)
          }
        `,
        variables: {
          id: user._id,
          key: 'mfaWarned',
          value: 'true',
        },
      } ).catch( () => {
        this.$alerts.coded( 'E005', 'F005' ); //see notifications spreadsheet
      } );
    },
    mfaCheck( input ) {
      this.externalMFACheck = input;
      this.forgotPassword = false;
    },
    mfaWarn( user ) {
      this.$apollo.query( {
        query: gql`
          query GetUserSetting($id: ObjectID!, $key: String!) {
            mfaWarned: GetUserSetting(id: $id, key: $key)
          }`,
        variables: {
          id: user._id,
          key: 'mfaWarned',
        },
      } ).then( ( { data: { mfaWarned } } ) => {
        const alreadyWarned = mfaWarned === 'true';

        if ( !user.mfaEnabled && !alreadyWarned ) {
          this.$alerts.warn( "You haven't enabled 2 factor authentication. For added security, it is recommended that you do so." );
          this.userHasBeenWarned( user );
        }
      } ).catch( () => {
        this.$alerts.coded( 'E002', 'F002' ); //see notifications spreadsheet
      } );
    },
    checkUser() {
      if(
        localStorage.hasOwnProperty( 'HUGR_ACCESS_TOKEN' )
        && localStorage.hasOwnProperty( 'HUGR_REFRESH_TOKEN' )
        ) {
        //is logged in, check token is valid
        this.$apollo.query( {
          query: gql`
            {
              user: WhoAmI {
                _id,
                email,
                name,
                aclevel,
                mfaEnabled,
                displayname,
                passwordResetRequired,
                permissionGroup {
                  _id,
                  name,
                  permissions {
                    id
                    permissionName,
                    permissionValue
                  },
                  categories {
                    hierarchy,
                    category {
                      _id
                    }
                  }
                },
                primaryTeam {
                  _id
                  identifier
                  mfaEnabled
                },
                settings {
                  key,
                  value
                }

                teams {
                  _id
                  name
                  mfaEnabled
                }
              }
            }
          `,
          fetchPolicy: 'no-cache',
        } ).then( res => {
          //set report
          if( this.$session.has( 'report' ) ) {
            this.setReport( this.$session.get( 'report' ) );
          }
          //set user
          const { user } = res.data;
          const team = user.primaryTeam ? user.primaryTeam.identifier : 'none';

          this.setUser( {
            email: user.email,
            id: user._id,
            displayname: user.displayname,
            name: user.name,
            aclevel: user.aclevel,
            permissionGroup: user.permissionGroup,
            accessToken: localStorage.getItem( 'HUGR_ACCESS_TOKEN' ),
            refreshToken: localStorage.getItem( 'HUGR_REFRESH_TOKEN' ),
            primaryTeam: user.primaryTeam,
            teams: user.teams.map( t => t._id ),
            settings: user.settings,
            mfaEnabled: user.mfaEnabled,
          } );

          if( this.getSettingValue( 'LanguageChoice' ) ) {
            const value = this.getSettingValue( 'LanguageChoice' );
            this.$language.current = value;
          }

          this.aclevel = user.aclevel;
          this.loaded = true;

          this.mfaWarn( user );
          // this.$gettext( 'Dashboard' );
          // this.$gettext( 'Issue Templates' );
          // this.$gettext( 'Solutions' );

          switch( this.aclevel ) {
            case 0: { //admin
              this.navigation = {
                Home: '/dashboard',
                '%{project}s': `/${config.reportRouterReplacement}s`,
                'My Team': `/team/${team}`,
              };
              // if( this.hasPortfolios ) {
              //   this.navigation = {
              //     ...this.navigation,
              //     'My Portfolios': `/portfolio/my`,
              //   };
              // }

              break;
            }
            case 1: { //tester
              this.navigation = {
                Home: '/dashboard',
                '%{project}s': `/${config.reportRouterReplacement}s`,
                'My Team': `/team/${team}`,
              };
              // if( this.hasPortfolios ) {
              //   this.navigation = {
              //     ...this.navigation,
              //     'My Portfolios': `/portfolio/my`,
              //   };
              // }

              break;
            }
            case 2: { //customer
              this.navigation = {
                Home: '/dashboard',
                '%{project}s': `/${config.reportRouterReplacement}s`,
                'My Team': `/team/${team}`,
              };
              // if( this.hasPortfolios ) {
              //   this.navigation = {
              //     ...this.navigation,
              //     'My Portfolios': `/portfolio/my`,
              //   };
              // }

              break;
            }
          }

          if( this.hasPermission( 'ComplianceDashboard', 'Enabled' ) ) this.navigation['Compliance & Reporting'] = '/compliance';

          const templates = {
            // _type: 'section',
            'Issue Templates': '/templates/issues',
            'Solution Templates': '/solutions',
          };

          if( this.hasPermission( 'SpreadsheetTemplates', 'Create' ) ) templates['Spreadsheet Templates'] = '/templates/spreadsheet';

          this.navigation = {
            ...this.navigation,
            Resources: {
              // _type: 'dropdown',
              Templates: templates,
            },
          };
          // this.navigation = {
          //   ...this.navigation,
          //   Resources: templates,
          // };

          const mfaTeams = team_ => !!team_.mfaEnabled;
          const userNeedsMfaFromTeams = user.teams.some( mfaTeams );

          this.requiresPasswordReset = user.passwordResetRequired;

          if ( !user.mfaEnabled && userNeedsMfaFromTeams ) {
            this.mfaTeams = user.teams.filter( mfaTeams );
            this.requiresMfaSetup = true;
          }
          // const Admin = { _type: 'dropdown' };
          const Admin = { };
          let ifAdmin = false;

          if( this.hasPermission( 'Customers', 'Create' ) || this.hasPermission( 'Customers', 'Update' ) || this.hasPermission( 'Customers', 'Delete' ) ) {
            Admin.Customers = '/customers';
            ifAdmin = true;
          }

          if( this.hasPermission( 'Teams', 'Create' ) || this.hasPermission( 'Teams', 'Update' ) || this.hasPermission( 'Teams', 'Delete' )
              || this.hasPermission( 'Users', 'Create' ) || this.hasPermission( 'Users', 'Update' ) || this.hasPermission( 'Users', 'Delete' )
              || this.hasPermission( 'Contacts', 'Create' ) || this.hasPermission( 'Contacts', 'Update' ) || this.hasPermission( 'Contacts', 'Delete' ) ) {
            Admin['Teams & Users'] = '/teams';
            ifAdmin = true;
          }

          if( this.hasPortfolios
              && ( this.hasPermission( 'Portfolios', 'Create' ) || this.hasPermission( 'Portfolios', 'Update' ) || this.hasPermission( 'Portfolios', 'Delete' ) ) ) {
            Admin.Portfolios = '/portfolios';
            ifAdmin = true;
          }

          if( this.hasPermission( 'ReportSections', 'Create' ) || this.hasPermission( 'ReportSections', 'Update' ) || this.hasPermission( 'ReportSections', 'Delete' ) ) {
            Admin['Report Sections'] = '/templates/report-sections';
            ifAdmin = true;
          }

          if( this.hasPermission( 'AccessibilityStatementSections', 'Create' ) || this.hasPermission( 'AccessibilityStatementSections', 'Update' ) || this.hasPermission( 'AccessibilityStatementSections', 'Delete' ) || this.hasPermission( 'AccessibilityStatementSections', 'ToggleActive' ) ) {
            Admin['Accessibility Statement Sections'] = '/accessibility-statement/statement-sections';
            ifAdmin = true;
          }

          if( this.hasPermission( 'ReportTypes', 'Create' ) || this.hasPermission( 'ReportTypes', 'Update' ) || this.hasPermission( 'ReportTypes', 'Delete' ) ) {
            Admin['Report Types'] = '/templates/report-types';
            ifAdmin = true;
          }

          if( this.hasPermissions( [ [ 'Admin','ManagePermissionGroups' ] ] ) ) {
            Admin['Permission Groups'] = '/permission-groups';
            ifAdmin = true;
          }

          if( this.hasPermissions( [ [ 'Admin', 'ManageSettings' ] ] ) ) {
            Admin['Settings'] = '/settings';
            ifAdmin = true;
          }

          if( ifAdmin && !this.hasPermissions( [ [ 'AdminPage','HideEvenIfAnyPrivileges' ] ] ) ) {
            this.navigation.Admin = Admin;
          }

        } ).catch( error => {
          this.$alerts.coded( 'E003', 'F003', '', error ); //see notifications spreadsheet
          this.$catchError( error );
          //Try refresh token
          const user = this.$session.get( 'user' );
          this.$apollo.mutate( {
            mutation: gql`
              mutation resetToken($name: String!, $email: String!, $aT: String!, $rT: String!) {
                refresh: resetToken(name: $name, email: $email, accessToken: $aT, refreshToken: $rT) {
                  success,
                  accessToken,
                  refreshToken,
                  user {
                    name,
                    email,
                    displayname,
                    aclevel
                  }
                }
              }
            `,
            variables:  {
              name: user.name,
              email: user.email,
              aT: localStorage.getItem( 'HUGR_ACCESS_TOKEN' ),
              rT: localStorage.getItem( 'HUGR_REFRESH_TOKEN' ),
            },
          } ).then( res => {
            const login = res.data.refresh;
            if( login.success ) {
              this.$session.set( 'jwt', login.accessToken );
              localStorage.setItem( 'HUGR_ACCESS_TOKEN', login.accessToken );

              this.$session.set( 'jwt-refresh', login.refreshToken );
              localStorage.setItem( 'HUGR_REFRESH_TOKEN', login.refreshToken );

              this.checkUser();
            }
          } ).catch( error => {
            this.$alerts.coded( 'E004', 'F004', '', error ); //see notifications spreadsheet
            this.loaded = true;
            //all is failed, give up
            localStorage.clear();
          } );
        } );

      } else {
        this.loaded = true;
      }
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
    ...mapGetters( [ 'hasPermissions', 'hasPermission', 'getSettingValue' ] ),
    routeHasPermissionRequirement() {
      if( this.$route.meta.requiredPermissions ) {
        return true;
      }

        return false;

    },
  },
  components: {
    Header,
    Login,
    Register,
    ForgotPassword,
    MFAForm,
    Body: UIBody,
    PasswordResetForm,

    Pill,
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: fs-me-regular;
  src: url(./assets/fonts/fs_me_web-regular.woff);
}
@font-face {
  font-family: fs-me-bold;
  src: url(./assets/fonts/fs_me_web-bold.woff);
}
@import '@/assets/styles/variables/_fonts.scss';
@import '@/assets/styles/variables/_header.scss';

html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  font-family: $hugr-fonts-body;
  h1, h2, h3, h4, h5, h6 {
    font-family: $hugr-fonts-headings;
    font-weight: 400;
  }
  p, span, th, td {
    font-family: $hugr-fonts-body;
  }
  a {
    color: $hugr-colours-tertiary;
    &:focus, &:hover {
      color: darken($hugr-colours-tertiary, 20%);
    }
  }
  button {
    font-family: $hugr-fonts-body;
  }
  &._darkify {
    background: $hugr-colours-primary;
  }
  ._darkMode {
    a {
      color: $hugr-colours-secondary;
    }
  }

  .Alerts a {
    color: #000;
  }
}
main:focus {
  outline: none;
}
#app {
  transition: background-color .5s ease-in-out 0s;
  font-family: $hugr-fonts-body;
  min-height: 100vh;
}
#app ._darkMode {
  background: $hugr-colours-primary;
  color: white;
  h1, h2, h3, h4, h5, h6 {
    color: #FFF;
  }
}
a.a11y-skip {
  position: absolute;
  top: 0;
  left: -1000px;
  z-index: 9999999999;
  background: black;
  color: white !important;
  padding: 10px;
  &:focus {
    left: 0;
  }
}
.ContentContainer {
  padding-top: $hugr-header-regular-height;
  height: calc(100vh - #{$hugr-header-regular-height});
  position: relative;

  &._undecorated {
    padding-top: 0;
    height: 100vh;
  }

  &._withFooter {
    padding-bottom: 32px;
  }

  &_Body {
    display: inline-block;
    vertical-align: top;
    position: absolute;
    right: 0;
    // height: 100%;
    width: 100%;

  }
}

.__success {
  color: $hugr-colours-green;
}

.__warn {
  color: #aa6600;
}

.__fade {
  color: darken( $hugr-colours-grey, 40% );
}

.__small {
  font-size: 0.8em;
}

.__micro {
  font-size: 0.6em;
}

._darkMode {
  .__success {
    color: lighten( $hugr-colours-green, 20% );
  }

  .__warn {
    color: #d78100;
  }

  .__fade {
    color: darken( $hugr-colours-grey, 20% );
  }
}

.__grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 10px;
  &_column {
    &_1 {
      grid-column-end: span 1;
    }
    &_2 {
      grid-column-end: span 2;
    }
    &_3 {
      grid-column-end: span 3;
    }
    &_4 {
      grid-column-end: span 4;
    }
    &_5 {
      grid-column-end: span 5;
    }
    &_6 {
      grid-column-end: span 6;
    }
    &_7 {
      grid-column-end: span 7;
    }
    &_8 {
      grid-column-end: span 8;
    }
    &_9 {
      grid-column-end: span 9;
    }
    &_10 {
      grid-column-end: span 10;
    }
    &_11 {
      grid-column-end: span 11;
    }
    &_12 {
      grid-column-end: span 12;
    }
  }
}

@media (max-width: 1000px) {
  .__grid {
    display: block;
    > * {
      width: 100%;
      margin-bottom: 5px;
    }
  }
}

.csv {
  & > * {
    &:after {
      content: ", ";
    }
  }
  & > *:last-child {
    &:after {
      content: "" !important;
    }
  }
}
.hljs {
  background: transparent !important;
  *, span {
    font-family: monospace !important;
  }
}
span.input {
  label {
    margin: 10px 0;
    display: block;
    color: #262e37;
  }
  input {
    padding: 10px;
    border: 1px solid #95989c;
    color: #262e37;
    border-radius: 2px;
    width: calc(100% - 20px);
  }
}
button.linkbutton {
  font-size: 1em;
  background: transparent;
  border: none;
  color: #3e4a5a;
  display: inline-block;
  position: relative;
  padding-right: 25px;
  text-align: left;
  &:focus, &:hover {
    color: #930056;
    cursor: pointer;
  }
  &:before {
    position: absolute;
    content: "\203A";
    top: 50%;
    right: 2%;
    width: 20px;
    display: block;
    z-index: 1;
    font-size: 24px;
    margin-top: -14px;
    font-size: 1.4rem;
    margin-top: -.7rem;
    line-height: 1;
  }
}
.a11yhide {
  position: absolute !important;
  height: 1px; width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.Footer {
  position: fixed;
  padding: 25px 32px 4px 32px;
  bottom: 0;
  right: -32px;
  width: 100%;
  background: linear-gradient(0deg, #FFF 0%, #FFF 60%, transparent 100%);
  z-index: 10;
  &_Left{
    display: inline-block;
    width: 100px;
    padding: 0 16px;
  }
  &_Right {
    display: inline-block;
    text-align: right;
    width: calc( 100vw - 164px );
    a {
      color: lighten( #004D79, 13% );
      margin-left: 16px;
    }
  }
}

._darkMode footer {
  background: linear-gradient(0deg, rgb(37 46 55) 0%, rgb(37 46 55) 60%, transparent 100%);
  a {
    color: darken( #ffb23e, 20% );
  }
}

@media print {
  main {
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    margin-left: 0 !important;
    width: 100% !important;
  }
}
</style>
