<template>
  <div class="PresenceChecks">
    <div class="PresenceChecks_Backdrop" @click="preClose()" v-if="open">
      <div v-if="stageClosure" class="PresenceChecks_Backdrop_StageClosure" aria-live="polite"><p>Click outside or press escape again to close modal</p></div>
    </div>
    <Button :class="['PresenceChecks_Btn', open?'_open':'']" type="ml" size="micro" pill="Beta" @click="checkScan()">Scan for relevance</Button>
    <div class="PresenceChecks_Details" v-if="open">
      <div class="PresenceChecks_Details_Body">
        <h2 class="PresenceChecks_Details_Body_Title">
          <img :src="require('@/assets/images/sparkle-black.svg')" alt="" />
          Scan for relevance
          <Pill class="PresenceChecks_Details_Body_Title_Pill" text="Beta" size="micro"/>
        </h2>
        <template v-if="blurb">
          <p>The Scan for relevance feature helps speed up your testing by automatically identifying and marking checks that don't apply to this page. For example, if no videos are detected, any video-related checks will be marked as 'Not applicable', saving you time and effort.</p>
          <h3>Please note:</h3>
          <ul>
            <li>This feature currently only supports pages that are publicly accessible (i.e. not begind a login).</li>
            <li>At this stage, the scan focuses on media-related checks, such as video and audio. Other types of checks will need to be reviewed manually.</li>
            <li>The scan covers all checks on the page regardless of who they are assigned to.</li>
          </ul>
          <p class="__emphasis">We're continuously enhancing this feature and expanding its coverage to supports more types of checks!</p>
        </template>
        <span class="PresenceChecks_Details_Body_Scanning" v-else-if="scanning">Scanning...</span>
        <template v-else>
          <div class="PresenceChecks_Details_Body_Check">
            <h3>
              <Icon type="solid" icon="check" />
              Scanned page for images
            </h3>
            <p v-if="image.confidence<CONFIDENCE_THRESHOLD">Sorry, we could not determine if there were any images on this page.</p>
            <p v-else-if="image.presence">
              <span class="__emphasis">Image(s) found&nbsp;</span>
              <span>- you will need to conduct any image-related checks</span>
            </p>
            <p v-else>No images found</p>
          </div>

          <div class="PresenceChecks_Details_Body_Check">
            <h3>
              <Icon type="solid" icon="check" />
              Scanned page for video
            </h3>
            <p v-if="video.confidence<CONFIDENCE_THRESHOLD">Sorry, we could not determine if there were any videos on this page.</p>
            <p v-else-if="video.presence">
              <span class="__emphasis">Video found&nbsp;</span>
              <span>- you will need to conduct any video-related checks</span>
            </p>
            <p v-else>No video found</p>
          </div>

          <div class="PresenceChecks_Details_Body_Check">
            <h3>
              <Icon type="solid" icon="check" />
              Scanned page for Audio
            </h3>
            <p v-if="audio.confidence<CONFIDENCE_THRESHOLD">Sorry, we could not determine if there was any audio on this page.</p>
            <p v-else-if="audio.presence">
              <span class="__emphasis">Audio found&nbsp;</span>
              <span>- you will need to conduct any audio-related checks</span>
            </p>
            <p v-else>No audio found</p>
          </div>
          <div class="PresenceChecks_Details_Body_NextSteps">
            <p>
              <Icon type="solid" icon="list-check" />&nbsp;
              <span>Continuing will mark the irrelevant checks as <span class="__emphasis">'Not applicable'</span></span>
            </p>
          </div>
        </template>
      </div>
      <div class="PresenceChecks_Details_Actions">
        <div class="PresenceChecks_Details_Actions_Left">
          <Button type="transparent" size="micro" @click="close()">Cancel</Button>
        </div>
        <div class="PresenceChecks_Details_Actions_Right">
          <span v-if="blurb">
            <label>
              <input type="checkbox" v-model="disableBlurb" />
              Don't show this again
            </label>
          </span>
          <Button v-if="blurb||scanning" size="micro" type="secondary" @click="scan()" ref="scanBtn">Begin scan</Button>
          <Button v-else size="micro" type="secondary" @click="proceed()">Proceed</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { toRefs, ref } from 'vue';
  import { useLazyQuery } from "@vue/apollo-composable";
  import gql from "graphql-tag";

  import Pill from '@/components/UI/Pill';

  const CONFIDENCE_THRESHOLD = 10;

  const props = defineProps( {
    url: {
      type: String,
      required: true,
    },
  } );

  const { url } = toRefs( props );

  const { load: loadPresence, refetch: refetchPresence, onResult: onPresence } = useLazyQuery( gql`
    query CheckMediaPresence($url: String) {
      media: checkMediaPresence(url: $url) {
        image {
          presence
          confidence
        }
        video {
          presence
          confidence
        }
        audio {
          presence
          confidence
        }
      }
    }
  `,
  {
    url,
  },
  {
    fetchPolicy: 'no-cache',
  } );

  const open = ref( false );
  const blurb = ref( true );
  const scanning = ref( true );

  const stageClosure = ref( false );

  const image = ref( null );
  const video = ref( null );
  const audio = ref( null );

  const scanBtn = ref( null );
  const disableBlurb = ref( false );

  if( localStorage.getItem( 'DISABLE_SCAN_BLURB' ) == 'true' ) blurb.value = false;

  onPresence( ( { data } ) => {
    image.value = data.media.image;
    video.value = data.media.video;
    audio.value = data.media.audio;
    scanning.value = false;
  } );

  const scan = () => {
    open.value = true;
    blurb.value = false;
    scanning.value = true;

    if( disableBlurb.value ) localStorage.setItem( 'DISABLE_SCAN_BLURB', true );

    setTimeout( () => {
      scanBtn.value.setLoading( true );
    }, 100 );

    // eslint-disable-next-line no-unused-expressions
    loadPresence() || refetchPresence();
  };

  const checkScan = () => {
    open.value = true;
    stageClosure.value = false;
    if( !blurb.value ) scan();
  };

  const close = () => {
    open.value = false;
    scanning.value = false;
  };

  const preClose = () => {
    if( stageClosure.value ) close();
    else stageClosure.value = true;
  };

  const emit = defineEmits( [ 'mark' ] );

  const proceed = () => {
    let criteria = [];
    if( image.value.confidence >= CONFIDENCE_THRESHOLD && !image.value.presence ) criteria = [ '1.1.1', '1.4.5', ...criteria ];
    if( video.value.confidence >= CONFIDENCE_THRESHOLD && !video.value.presence ) criteria = [ '1.2.1 (b)', '1.2.2', '1.2.3', '1.2.4', '1.2.5', ...criteria ];
    if( audio.value.confidence >= CONFIDENCE_THRESHOLD && !audio.value.presence ) criteria = [ '1.2.1 (a)', '1.4.2', ...criteria ];

    emit( 'mark', criteria );
    close();
  };
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables/_buttons.scss';
  .PresenceChecks {
    &_Btn {
      &._open {
        z-index: 99999;
      }
    }

    &_Backdrop {
      background: #8080805e;
      position: fixed;
      left: 0;
      top: 0;
      height: 100vh;
      width: 100vw;
      z-index: 9999;

      &_StageClosure {
        position: absolute;
        width: 100%;
        top: 24px;
        z-index: 99999;
        p {
          margin: auto;
          width: 35%;
          max-width: 400px;
          text-align: center;
          background: white;
          padding: 8px;
          border-radius: 5px;
        }
      }
    }

    &_Details {
      position: absolute;
      width: 500px;
      height: 300px;
      background: white;
      border-radius: 6px;
      z-index: 99999;
      padding: 16px;
      right: 0;
      margin-top: 6px;

      &_Body {
        height: 240px;

        .__emphasis {
          font-weight: bold;
        }

        &_Title {
          margin: 0;
          font-family: $hugr-fonts-body;
          font-weight: bold;
          vertical-align: middle;
          &_Pill {
            vertical-align: top;
            float: right;
            top: -6px;
          }
        }

        &_Scanning {
          display: block;
          padding: 64px 210px;
        }

        p {
          color: $hugr-colours-primary;
          font-size: 0.8em;
          margin: 4px 0;
        }
        h3 {
          font-family: $hugr-fonts-body;
          font-weight: bold;
          font-size: 0.8em;
          margin: 4px 0;
        }
        ul {
          margin: 4px 0 8px;
          padding-left: 16px;
          li {
            color: $hugr-colours-primary;
            font-size: 0.8em;
          }
        }

        &_Check {
          margin: 16px 0;

          p {
            color: lighten( $hugr-colours-primary, 10% );
          }
        }

        &_NextSteps {
          background: lighten( $dig-blue, 70% );
          padding: 8px;
        }
      }

      &_Actions {
        margin-top: 16px;
        padding-top: 16px;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $hugr-colours-grey;

        &_Left{

        }

        &_Right {
          label {
            font-size: 0.8em;
            margin-right: 8px;
            input {
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
</style>
