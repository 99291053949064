<template>
  <Modal
    ref="modal"
    :title="$gettext( 'Export %{projectString} issues to csv', { projectString: $hugrConfig.projectString } )"

    :showActions="true"

    :loading="exporting"
    loadingText="Exporting..."

    :submitText="$gettext('Export')"
    @submit="exportCsv"

    @close="reset()">
    <div v-for="opt in Object.keys(opts)" :key="opt">
      <label>
        <input type="checkbox" v-model="opts[opt]">
        <span>{{ opt }}</span>
      </label>
    </div>

  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import FormInput from '@/components/Form/Input';
import FormTextArea from '@/components/Form/TextArea';
import moment from 'moment';
// import FormSelect from '@/components/Form/Select';

export default {
  name: 'CsvPortfolioModal',
  data() {
    return {
      id: false,
      data: false,
      opts: {
        id: false,
        identifier: true,
        title: true,
        description: true,
        URL: true,
        "Product Owner name": true,
        "Product Owner email": true,
        "Product Risk": true,
        "Product Risk Description": false,
        "Disproportionate Burden": true,
        "Disproportionate Burden Description": false,
        "Statement Location": false,
        "Stated Compliance": false,
        "Last reviewed date": false,
        "Legal Wording": false,
        "Last assured date": false,
        "Accessibility Statement Notes": false,
        "Actual compliance": true,
        "Number of issues": true,
      },
      results: [],
      exporting: false,
    };
  },
  methods: {
    exportCsv() {
      this.exporting = true;
      const rows = [];

      const headers = [];
      for( const opt of Object.keys( this.opts ) ) {
        if( this.opts[opt] ) {
          headers.push( opt );
        }
      }
      rows.push( headers );

      for( const report of this.results ) {
        const row = [];

        for( const opt of Object.keys( this.opts ) ) {
          if( this.opts[opt] ) {
            row.push( report[opt] );
          }
        }

        rows.push( row );
      }

      const csvContent = `data:text/csv;charset=utf-8,${
         rows.map( e => e.map( i => `"${i}"` ).join( "," ) ).join( "\n" )}`;

      const encodedUri = encodeURI( csvContent );
      window.open( encodedUri );
      this.exporting = false;
    },
    getData() {
      this.$apollo.query( {
        query: gql`
          query Portfolio($id: ObjectID) {
            portfolio: Portfolio(id: $id) {
              _id
              identifier
              name
              reports {
                _id
                identifier
                title
                productUrl,
                productStatementUrl,
                productDescription,

                productRisk,
                productRiskDescription,
                disproportionateBurden,
                disproportionateBurdenDescription,
                accessibilityStatement,
                accessibilityStatementNotes,

                owner {
                  name
                  email
                }

                currentVersion {
                  _id
                  isCompliant
                  hasOpenIssues
                  numberOfIssues

                  accessibilityStatementOverrides {
                    lastReviewDate
                    complianceStatus
                    statementURL
                    legalStatus
                    lastAssuredDate
                    assuranceNote
                  }
                }
              }
            }
          }
        `,
        variables: {
          id: this.id,
        },
      } ).then( res => {
        this.data = res.data.portfolio;
        const results = [];
        for( const report of this.data.reports ) {
          results.push( {
            id: report._id,
            identifier: report.identifier,
            title: report.title,
            description: report.productDescription,
            URL: report.productUrl,
            "Product Owner name": report.owner.name,
            "Product Owner email": report.owner.email,
            "Product Risk": this.riskString( report.productRisk ),
            "Product Risk Description": report.productRiskDescription,
            "Disproportionate Burden": this.disproportionateBurdenString( report.disproportionateBurden ),
            "Disproportionate Burden Description": report.disproportionateBurdenDescription,
            "Statement Location": report.currentVersion.accessibilityStatementOverrides.statementURL,
            "Stated Compliance": this.processOverride( report.currentVersion.accessibilityStatementOverrides.complianceStatus ),
            "Last reviewed date": this.processDate( report.currentVersion.accessibilityStatementOverrides.lastReviewDate ),
            "Legal Wording": this.processOverride( report.currentVersion.accessibilityStatementOverrides.legalStatus ),
            "Last assured date": this.processDate( report.currentVersion.accessibilityStatementOverrides.lastAssuredDate ),
            "Accessibility Statement Notes": report.currentVersion.accessibilityStatementOverrides.assuranceNote,
            "Actual compliance": this.actualComplianceString( report.currentVersion.isCompliant, report.currentVersion.hasOpenIssues ),
            "Number of issues": report.currentVersion.numberOfIssues,
          } );
        }
        this.results = results;
      } ).catch( () => {
        this.$alerts.coded( 'E050', 'F2701' ); //see notifications spreadsheet
      } );
    },
    show( id ) {
      this.id = id;
      this.getData();
      this.$refs.modal.show();
    },
    reset() {
      this.id = false;
      this.data = false;
      this.results = [];
      this.$refs.modal.reset();
    },

    riskString( value ) {
      let ret = 'Unknown';
      switch( value ) {
        case '0': {
          ret = 'low';

          break;
        }
        case '1': {
          ret = 'medium';

          break;
        }
        case '2': {
          ret = 'high';

          break;
        }
      }

      return this.$gettext( ret );
    },
    disproportionateBurdenString( value ) {
      let ret = 'Unknown';
      switch( value ) {
        case '0': {
          ret = 'none';

          break;
        }
        case '1': {
          ret = 'being considered';

          break;
        }
        case '2': {
          ret = 'made';

          break;
        }
        case '3': {
          ret = 'expired';

          break;
        }
      }

      return this.$gettext( ret );
    },
    processDate( input ) {
      let ret = 'No data';
      
      if ( moment( input, true ).isValid() ) return moment( input ).format( 'Do MMMM YYYY' );

      if( input != '' ) {
        ret = input;
      }

      return ret;
    },
    processOverride( input ) {
      let ret = 'No data';

      if( input != '' ) {
        ret = input;
      }

      return ret;
    },
    actualComplianceString( isCompliant, hasOpenIssues ) {
      let ret = '';
      if( isCompliant ) {
        ret = 'Is Compliant';
      } else {
        ret = 'Not Compliant';
      }

      if( isCompliant && hasOpenIssues ) {
        ret += ' with advisories';
      }

      return ret;
    },
  },
  components: {

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
