<template>
  <Widget :title="$gettext( 'Portfolios' )" :hideTitle="true" class="PortfoliosWidget" nominalWidth="2">
    <Pie class="PortfoliosWidget_Pie" :data="pieData" :cardMode="true" size="micro" keyStyle="dots" :centerKey="$gettext( `portfolios` )" :enableCenterKey="false" :enableHeadingKey="true"/>
  </Widget>
</template>

<script setup>
  import { ref, computed, inject } from 'vue';
  import { useQuery } from "@vue/apollo-composable";
  import gql from "graphql-tag";

  import Pie from '@/components/UI/Pie';

  const alerts = inject( 'alerts' );

  const { onResult, refetch, onError } = useQuery( gql`
    query Portfolios {
      portfolios: Portfolios {
        _id
        name
        isCompliant
      }
    }
  ` );

  const portfolios = ref( false );

  onResult( ( { data } ) => {
    if( data ) portfolios.value = data.portfolios;
  } );

  onError( error => {
    alerts.generic( error );
  } );

  const pieData = computed( () => {
    console.log( 'making pie data', portfolios.value );
    if( !portfolios.value ) return [];

    return [
      {
        key: 'Compliant',
        value: portfolios.value.filter( p => p.isCompliant ).length,
        colour: '#00a648',
      },
      {
        key: 'Not Compliant',
        value: portfolios.value.filter( p => !p.isCompliant ).length,
        colour: '#fe3246',
      },
    ];
  } );
</script>

<style lang="scss" scoped>

</style>
