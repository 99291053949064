<template>
  <!-- <i :class="cStyle+' fa-'+icon" :aria-label="read"></i> -->
  <font-awesome-icon v-if="preset=='portfolio'" :class="['Icon', spin?'_spin':'']" :icon="['solid', 'boxes-stacked']" :aria-label="read" :style="colour!=''?'color: '+colour:''"></font-awesome-icon>
  <font-awesome-icon v-else-if="preset=='project'" :class="['Icon', spin?'_spin':'']" :icon="['solid', 'box']" :aria-label="read" :style="colour!=''?'color: '+colour:''"></font-awesome-icon>
  <font-awesome-icon v-else-if="preset=='page'" :class="['Icon', spin?'_spin':'']" :icon="['regular', 'file']" :aria-label="read" :style="colour!=''?'color: '+colour:''"></font-awesome-icon>
  <font-awesome-icon v-else-if="preset=='component'" :class="['Icon', spin?'_spin':'']" :icon="['solid','puzzle-piece']" :aria-label="read" :style="colour!=''?'color: '+colour:''"></font-awesome-icon>
  <font-awesome-icon v-else-if="preset=='userjourney'" :class="['Icon', spin?'_spin':'']" :icon="['solid', 'route']" :aria-label="read" :style="colour!=''?'color: '+colour:''"></font-awesome-icon>

  <font-awesome-icon v-else :class="['Icon', spin?'_spin':'']" :icon="[cStyle, icon]" :aria-label="read" :style="colour!=''?'color: '+colour:''"></font-awesome-icon>
  <!-- <FontAwesomeIcon :icon="icon" :aria-label="read"/> -->
</template>

<script>
export default {
  name: 'UIIcon',
  computed: {
    cStyle() {
      let retStyle = 'far';
      switch( this.type ) {
        case 'solid': {
          retStyle = 'fas';

          break;
        }
        case 'light': {
          retStyle = 'fal';

          break;
        }
        case 'brands': {
          retStyle = 'fab';

          break;
        }
        case 'duutone': {
          retStyle = 'fad';

          break;
        }
      }

      return retStyle;
    },
  },
  props: {
    preset: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'regular',
    },
    read: {
      type: String,
      default: '',
    },
    colour: {
      type: String,
      default: '',
    },
    spin: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  // @-moz-keyframes spin {
  //   from { -moz-transform: rotate(0deg); }
  //   to { -moz-transform: rotate(360deg); }
  // }
  // @-webkit-keyframes spin {
  //   from { -webkit-transform: rotate(0deg); }
  //   to { -webkit-transform: rotate(360deg); }
  // }
  @keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }
  .Icon {
    &._spin {
      animation-name: spin;
      animation-duration: 4000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
</style>
